import { useEffect, useRef } from 'react';

/**
 * Hook that returns `true` if the component is in its first render.
 */
export function useFirstMountState() {
  const isFirstMount = useRef(true);

  useEffect(() => {
    isFirstMount.current = false;
  }, []);

  return isFirstMount.current;
}
