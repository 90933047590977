/*
 * Detect if the user is using an iOS device
 * */
export function isIOS() {
  return (
    typeof navigator !== 'undefined' &&
    /(iPad|iPhone|iPod)/.test(navigator.userAgent) &&
    !(window as { MSStream?: boolean }).MSStream
  );
}
