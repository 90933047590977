import { DependencyList, EffectCallback, useEffect } from 'react';

import { useFirstMountState } from './useFirstMountState';

/**
 * Hook that runs an effect only on updates, not on mount.
 * @param effect The effect to run.
 * @param deps The dependencies to watch for changes.
 */
export function useUpdateEffect(effect: EffectCallback, deps: DependencyList) {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (isFirstMount) {
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
